import { createRouter, createWebHistory } from "vue-router";
import { useDataStore } from "../stores/data";

// layouts

import Admin from "@/layouts/Admin.vue";
import Employee from "@/layouts/Employee.vue";
import EditEmployee from "@/layouts/EditEmployee.vue";
import SettingsLayout from "@/layouts/Settings.vue";

// views for Admin layout
import BeforeDashboard from "@/views/admin/BeforeDashboard.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Wallet from "@/views/admin/Wallet.vue";
import TaxFiling from "@/views/admin/tax-filing/ViewTaxFiling.vue";
import TaxfilingApplication from "@/views/admin/tax-filing/Application.vue";
import Support from "@/views/admin/Support.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import APIOverview from "@/views/api-dashboard/overview.vue";
import apiProduct from "@/views/api-dashboard/api-keys/product.vue";
import apiProductDetail from "@/views/api-dashboard/api-keys/productDetail.vue";
import apiTestKeys from "@/views/api-dashboard/api-keys/test-keys.vue";
import apiProfile from "@/views/api-dashboard/profile.vue";
import apiTeam from "@/views/api-dashboard/team.vue";
import apiPassword from "@/views/api-dashboard/settings/password.vue";
import apiSupport from "@/views/api-dashboard/settings/support.vue";
import apiViewAll from "@/views/api-dashboard/viewAll.vue";

// website pages
import Landing from "@/www/pages/landing/index.vue";

const routes = [
  /*---- website routes  ----*/
  {
    path: "/admin/before-dashboard",
    name: "BeforeDashboard",
    component: BeforeDashboard,
  },
  {
    path: "/payInvoice",
    name: "PayInvoice",
    component: () => import("@/views/public/PayInvoice.vue"),
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/download-app",
    name: "AppClick",
    component: () => import("@/www/pages/AppClick.vue"),
  },
  {
    path: "/wnpLanding",
    name: "WnpLanding",
    component: () => import("@/www/pages/wnp/index.vue"),
  },
  {
    path: "/individual",
    name: "Individual",
    component: () => import("@/www/pages/individual/index.vue"),
  },
  {
    path: "/business",
    name: "Business",
    component: () => import("@/www/pages/business/index.vue"),
  },
  {
    path: "/manage-employee",
    name: "DeviceManagement",
    component: () => import("@/www/pages/mgt/index.vue"),
  },
  {
    path: "/assess-employee",
    name: "AssessEmployee",
    component: () => import("@/www/pages/assessment/index.vue"),
  },
  {
    path: "/hr-stores",
    name: "HrStores",
    component: () => import("@/www/pages/hrstores/index.vue"),
  },
  {
    path: "/paye",
    name: "PayePage",
    component: () => import("@/www/pages/paye/index.vue"),
  },
  {
    path: "/developers",
    name: "Developers",
    component: () => import("@/www/pages/developers/index.vue"),
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("@/www/pages/about/index.vue"),
  },
  {
    path: "/learn-more",
    name: "LearnMore",
    component: () => import("@/www/pages/learn-more/index.vue"),
  },
  // {
  //   path: "/manage-employee",
  //   name: "DeviceManagement",
  //   component: () => import("@/www/pages/mgt/index.vue"),
  // },
  // {
  //   path: "/assess-employee",
  //   name: "AssessEmployee",
  //   component: () => import("@/www/pages/assessment/index.vue"),
  // },
  // {
  //   path: "/hr-stores",
  //   name: "HrStores",
  //   component: () => import("@/www/pages/hrstores/index.vue"),
  // },
  // {
  //   path: "/paye",
  //   name: "PayePage",
  //   component: () => import("@/www/pages/paye/index.vue"),
  // },
  {
    path: "/developers/documentation",
    name: "Documentation",
    component: () => import("@/www/pages/developers/documentation.vue"),
  },
  {
    path: "/developers/dashboard",
    redirect: "/developers/dashboard/overview",

    name: "APIDashboard",
    component: () => import("@/www/pages/developers/dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/developers/dashboard/overview",
        name: "APIDashboardOverview",
        component: APIOverview,
      },
      {
        path: "/developers/dashboard/api-keys/product",
        name: "APIProduct",
        component: apiProduct,
      },
      {
        path: "/developers/dashboard/api-keys/product/:id",
        name: "APIProductDetails",
        component: apiProductDetail,
      },
      {
        path: "/developers/dashboard/product/viewAll",
        name: "APIViewAll",
        component: apiViewAll,
      },
      {
        path: "/developers/dashboard/api-keys/service/:id",
        name: "APIServiceDetails",
        component: () => import("../views/api-dashboard/api-keys/service.vue"),
        meta: { props: true },
      },
      {
        path: "/developers/dashboard/api-keys/test-keys",
        name: "APITestKeys",
        component: apiTestKeys,
      },
      {
        path: "/developers/dashboard/profile",
        name: "APIProfile",
        component: apiProfile,
      },
      {
        path: "/developers/dashboard/team",
        name: "APITeam",
        component: apiTeam,
      },
      {
        path: "/developers/dashboard/settings/password",
        name: "APIPassword",
        component: apiPassword,
      },
      {
        path: "/developers/dashboard/settings/support",
        name: "APISupport",
        component: apiSupport,
      },
    ],
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/www/pages/privacy-policy.vue"),
  },
  {
    path: "/wnp/privacy-policy",
    name: "WNPPrivacyPolicy",
    component: () => import("@/www/pages/wnp/privacy-policy.vue"),
  },

  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: () => import("@/www/pages/terms-of-use.vue"),
  },
  {
    path: "/wnp/terms-of-use",
    name: "WNPTermsOfUse",
    component: () => import("@/www/pages/wnp/terms-of-use.vue"),
  },

  /*--- website routes end -----*/

  {
    path: "/register",
    name: "Register",
    component: () => import("../views/main/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/main/Login.vue"),
  },
  {
    path: "/reset-password",
    name: "Forgot",
    component: () => import("../views/main/ForgotPassword.vue"),
  },

  {
    path: "/admin",
    redirect: "/admin/dashboard",
    name: "AdminDashboard",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/admin/tax-filing",
        component: TaxFiling,
      },
      {
        path: "/admin/tax-filing/application",
        name: "AddTaxFiling",
        component: TaxfilingApplication,
      },
      {
        path: "/admin/support/talk-to-us",
        component: Support,
      },

      {
        path: "/admin/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/wallet/:id",
        component: Wallet,
        meta: { props: true },
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/tables",
        component: Tables,
      },
      {
        path: "/admin/maps",
        component: () => import("@/views/admin/Maps.vue"),
      },
      {
        path: "/employee/add",
        name: "AddEmployee",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/employee/AddEmployee.vue"
          );
        },
      },
      {
        path: "/employee/view",
        name: "ViewEmployee",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/employee/ViewEmployee.vue"
          );
        },
      },

      {
        path: "/employee/view/archived",
        name: "Archived",
        component: () => import("../views/admin/employee/Archived.vue"),
      },

      {
        path: "/employee",
        redirect: "/employee/view/personal-info/",
        component: Employee,

        children: [
          {
            path: "/employee/view/personal-info/:id",
            name: "PersonalInfo",
            component: () =>
              import("../views/admin/employee/profile/PersonalInfo.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/view/education-info/:id",
            name: "EducationalInfo",
            component: () =>
              import("../views/admin/employee/profile/EducationalInfo.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/view/job-history/:id",
            name: "JobHistory",
            component: () =>
              import("../views/admin/employee/profile/JobHistory.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/view/documents/:id",
            name: "EmployeeDocuments",
            component: () =>
              import("../views/admin/employee/profile/EmployeeDocuments.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/view/benefits/:id",
            name: "EmployeeBenefits",
            component: () =>
              import("../views/admin/employee/profile/EmployeeBenefits.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/view/company-assets/:id",
            name: "CompanyAssets",
            component: () =>
              import("../views/admin/employee/profile/CompanyAssets.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/view/emergency-contact/:id",
            name: "EmergencyContact",
            component: () =>
              import("../views/admin/employee/profile/EmergencyContact.vue"),
            meta: { props: true },
          },
        ],
      },

      {
        path: "/employee/edit",
        name: "EditEmployee",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/employee/EditEmployee.vue"
          );
        },
      },
      {
        path: "/employee/edit/bulk",
        name: "EmployeeBulkEdit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/employee/edit/BulkEdit.vue"
          );
        },
      },
      {
        path: "/employee",
        redirect: "/employee/edit/personal-info",
        component: Employee,

        children: [
          {
            path: "/employee/edit/personal-info/:id",
            name: "EditPersonalInfo",
            component: () =>
              import("../views/admin/employee/edit/PersonalInfo.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/edit/education-info/:id",
            name: "EditEducationalInfo",
            component: () =>
              import("../views/admin/employee/edit/EducationalInfo.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/edit/job-history/:id",
            name: "EditJobHistory",
            component: () =>
              import("../views/admin/employee/edit/JobHistory.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/edit/documents/:id",
            name: "EditEmployeeDocuments",
            component: () =>
              import("../views/admin/employee/edit/EmployeeDocuments.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/edit/benefits/:id",
            name: "EditEmployeeBenefits",
            component: () =>
              import("../views/admin/employee/edit/EmployeeBenefits.vue"),
            meta: { props: true },
          },
          {
            path: "/employee/edit/company-assets/:id",
            name: "EditCompanyAssets",
            component: () =>
              import("../views/admin/employee/edit/CompanyAssets.vue"),
          },
          {
            path: "/employee/edit/emergency-contact/:id",
            name: "EditEmergencyContact",
            component: () =>
              import("../views/admin/employee/edit/EmergencyContact.vue"),
            meta: { props: true },
          },
        ],
      },

      //PAYROLL - ADD SALARY
      {
        path: "/payroll/add",
        name: "AddSalary",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/AddSalary.vue"
          );
        },
      },

      //VIEW SALARY
      {
        path: "/payroll/view",
        name: "ViewSalary",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/ViewSalary.vue"
          );
        },
      },

      {
        path: "/payroll/view/salary/:id",
        name: "ViewSingleSalary",
        component: function () {
          return import("../views/admin/payroll/view/SingleView.vue");
        },
        meta: { props: true },
      },
      {
        path: "/payroll/payslip",
        name: "Payslip",
        component: function () {
          return import("../views/admin/payroll/payslip/Payslip.vue");
        },
      },
      {
        path: "/payroll/payslip/:date",
        name: "PayslipDetails",
        component: function () {
          return import("../views/admin/payroll/payslip/PayslipDetails.vue");
        },
      },

      {
        path: "/payroll/edit",
        name: "EditSalary",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/EditSalary.vue"
          );
        },
      },
      {
        path: "/payroll/paysalary/:id?",
        name: "PaySalary",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/PaySalary.vue"
          );
        },
      },
      {
        path: "/payroll/edit/bulk",
        name: "SalaryBulkEdit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/edit/BulkEdit.vue"
          );
        },
      },

      {
        path: "/payroll/edit/salary/:id",
        name: "EditSingleSalary",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/edit/SingleEdit.vue"
          );
        },
        meta: { props: true },
      },
      {
        path: "/payroll/salary/add/bonus-and-deductions",
        name: "AddBonus",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/edit/BonusAdd.vue"
          );
        },
        meta: { props: true },
      },
      {
        path: "/payroll/salary/:action/bonus-and-deductions/:id",
        name: "EditSingleBonus",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/edit/BonusEdit.vue"
          );
        },
        meta: { props: true },
      },
      {
        path: "/payroll/salary/bonus-and-deductions",
        name: "BonusAndDeduction",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/BonusAndDeduction.vue"
          );
        },
      },
      {
        path: "/payroll/salary/bonus-and-deductions/history",
        name: "BonusAndDeductionHistory",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/BonusHistory.vue"
          );
        },
      },
      {
        path: "/payroll/payroll-history",
        name: "PayrollHistory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/history/PayrollHistory.vue"
          );
        },
      },
      {
        path: "/payroll/payroll-history/:date",
        name: "PayrollHistoryDetails",
        component: function () {
          return import(
            "../views/admin/payroll/history/PayrollHistoryDetails.vue"
          );
        },
      },
      {
        path: "/payroll/payroll-report",
        name: "PayrollReport",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/report/PayrollReport.vue"
          );
        },
      },
      {
        path: "/payroll/detailed-payroll-report",
        name: "DetailedPayrollReport",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/report/DetailedReport.vue"
          );
        },
      },
      {
        path: "/payroll/payroll-analysis",
        name: "PayrollAnalysis",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/payroll/analysis/PayrollAnalysis.vue"
          );
        },
      },
      {
        path: "/payroll/salary-advance",
        name: "WNPSalaryAdvance",

        component: function () {
          return import("../views/admin/payroll/wnp/SalaryAdvance.vue");
        },
      },

      //COMPLIANCE

      {
        path: "/compliance/register",
        name: "RegisterCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import("../views/admin/compliance/RegisterCompliance.vue");
        },
      },
      {
        path: "/compliance/history",
        name: "ComplianceHistory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/ComplianceHistory.vue"
          );
        },
      },

      {
        path: "/compliance/add",
        name: "AddCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/AddCompliance.vue"
          );
        },
      },

      {
        path: "/compliance/view",
        name: "ViewCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/ViewCompliance.vue"
          );
        },
      },
      {
        path: "/compliance/history/view",
        name: "ViewComplianceHistory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/ViewComplianceHistory.vue"
          );
        },
      },

      {
        path: "/compliance/transaction/history/:id",
        name: "ViewSingleTransactionHistory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/ViewSingleTransactionHistory.vue"
          );
        },
      },

      {
        path: "/compliance/edit",
        name: "EditCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/EditCompliance.vue"
          );
        },
      },

      {
        path: "/compliance/edit/:id",
        name: "EditSingleCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/EditSingleCompliance.vue"
          );
        },
      },
      {
        path: "/compliance/view/:id",
        name: "ViewSingleCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/ViewSingleCompliance.vue"
          );
        },
      },

      {
        path: "/compliance/pay",
        name: "PayCompliance",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/compliance/PayCompliance.vue"
          );
        },
      },

      //SETTINGS - PROFILE
      {
        path: "/settings",
        redirect: "/settings/profile",
        component: SettingsLayout,

        children: [
          {
            path: "/settings/profile",
            name: "Profile",
            component: () => import("../views/admin/settings/Profile.vue"),
            meta: { props: true },
          },
          {
            path: "manage-account",
            name: "ManageAccount",
            component: () =>
              import("../views/admin/settings/ManageAccount.vue"),
            meta: { props: true },
          },
          {
            path: "business-profile",
            name: "BusinessProfile",
            component: () =>
              import("../views/admin/settings/BusinessProfile.vue"),
            meta: { props: true },
          },
          {
            path: "/settings/payment",
            name: "PayrollSettings",
            component: () =>
              import("../views/admin/settings/PayrollSettings.vue"),
            meta: { props: true },
          },
          {
            path: "team",
            name: "Team",
            component: () => import("../views/admin/settings/Team.vue"),
            meta: { props: true },
          },
          {
            path: "security",
            name: "Security",
            component: () => import("../views/admin/settings/Security.vue"),
            meta: { props: true },
          },
          {
            path: "/settings/pricing",
            name: "Pricing",
            component: () => import("../views/admin/settings/Pricing.vue"),
          },
          {
            path: "refer",
            name: "Refer",
            component: () => import("../views/admin/settings/Refer.vue"),
          },
        ],
      },

      //LOAN
      {
        path: "/loan",
        name: "LoanApplication",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/loan/Index.vue"
          );
        },
      },
      {
        path: "/loan/apply/:id",
        name: "ApplyForLoan",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/loan-old/ApplyForLoan.vue"
          );
        },
      },
      {
        path: "/loan/manage",
        name: "ManageLoans",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/loan-old/Manage.vue"
          );
        },
      },
      {
        path: "/loan/history",
        name: "LoanHistory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/admin/loan-old/History.vue"
          );
        },
      },

      {
        path: "/book-keeping/client/add",
        name: "AddClient",
        component: function () {
          return import("../views/admin/book-keeping/Client/Add.vue");
        },
      },
      {
        path: "/book-keeping/client",
        name: "BookKeepingClient",
        component: function () {
          return import("../views/admin/book-keeping/Client/Index.vue");
        },
      },
      {
        path: "/book-keeping/client?:id",
        name: "ViewClient",
        component: function () {
          return import("../views/admin/book-keeping/Client/View.vue");
        },
      },

      {
        path: "/book-keeping/receipt",
        name: "BookKeepingReceipt",
        component: function () {
          return import("../views/admin/book-keeping/Receipt/Index.vue");
        },
      },
      {
        path: "/book-keeping/receipt/add",
        name: "AddReceipt",
        component: function () {
          return import("../views/admin/book-keeping/Receipt/Add.vue");
        },
      },
      {
        path: "/book-keeping/receipt/:id",
        name: "ViewReceipt",
        component: function () {
          return import("../views/admin/book-keeping/Receipt/View.vue");
        },
      },

      {
        path: "/book-keeping/invoice",
        name: "BookKeepingInvoice",
        component: function () {
          return import("../views/admin/book-keeping/Invoice/Index.vue");
        },
      },
      {
        path: "/book-keeping/invoice/add",
        name: "AddInvoice",
        component: function () {
          return import("../views/admin/book-keeping/Invoice/Add.vue");
        },
      },
      {
        path: "/book-keeping/invoice/:id",
        name: "ViewInvoice",
        component: function () {
          return import("../views/admin/book-keeping/Invoice/View.vue");
        },
      },
      {
        path: "/book-keeping/inventory",
        name: "BookKeepingInventory",
        component: function () {
          return import("../views/admin/book-keeping/Inventory/Index.vue");
        },
      },
      {
        path: "/book-keeping/inventory/:id",
        name: "ViewInventory",
        component: function () {
          return import("../views/admin/book-keeping/Inventory/View.vue");
        },
      },
      {
        path: "/book-keeping/inventory/add",
        name: "AddInventory",
        component: function () {
          return import("../views/admin/book-keeping/Inventory/Add.vue");
        },
      },
      {
        path: "/book-keeping/settings",
        name: "BookKeepingSettings",
        component: function () {
          return import("../views/admin/book-keeping/Settings/Index.vue");
        },
      },
    ],
  },

  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  // ✅ This will work because the router starts its navigation after
  // the router is installed and pinia will be installed too
  const store = useDataStore();
  if (to.query && to.query.fromMobile && to.query.token) {
    window.localStorage.setItem("token", to.query.token);
    window.localStorage.setItem("refreshToken", to.query.refreshToken);
    store.$patch({
      // userPrivileges: res.privileges,
      isLoggedIn: true,
      fromApp: true,
    });
    next("/loan/application/view");
  }
  if (to.meta.requiresAuth && !store.isLoggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
