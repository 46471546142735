<template>
  <main>
    <div class="relative mt-1" v-bind="$attrs">
      <span
        v-if="label"
        class="absolute left-5 py-0 -top-2 px-5 bg-white text-sm text-newGray capitalize"
      >
        {{ label }}
      </span>
      <div
        style="border-radius: 16px"
        class="bg-white w-full font-medium text-sm leading-2 focus:outline-none border block appearance-none focus:border-primary"
        aria-readonly
        :id="outline ? 'outline-style' : 'grid-state'"
        @click.self="isSelect = !isSelect"
        :class="[
          `${value ? '  text-info-700' : '  text-dark-500'} ${
            isSelect ? 'border-primary' : 'border-dark-200 '
          }`,
          isSelect && searchable
            ? 'py-0 px-0'
            : currency
            ? 'py-1 px-4'
            : 'py-3 px-4',
        ]"
      >
        <form
          v-if="isSelect && searchable"
          action=""
          class="relative w-full z-10 top-0"
        >
          <easiTextInput
            v-model="searchQuery"
            @keyup="searchFunction"
            autofocus
            :forSelect="true"
          ></easiTextInput>
        </form>
        <span class="relative w-11/12" @click.self="isSelect = true" v-else>
          {{ value == "" && !currency ? placeholder : value }}</span
        >
        <div
          v-show="currency && !optionData.length && !addBankData"
          @click="isSelect = !isSelect"
          class="px-4 py-2 cursor-pointer text-sm flex justify-between gap-x-3 items-center"
        >
          <div class="flex items-center gap-4">
            <img :src="selectedCountry.currency" alt="" />
            <span class="text-dark-800 text-xs md:text-sm">{{
              selectedCountry.text
            }}</span>
          </div>
          <img class="hidden md:block" :src="selectedCountry.flag" alt="" />
          <img class="md:hidden" :src="selectedCountry.flag" alt="" />
        </div>

        <ul
          v-show="isSelect"
          :class="[
            full ? 'w-full' : 'w-10/12',
            autoHeight || optionData.length <= 5
              ? 'h-auto  overflow-y-hidden'
              : 'h-40 overflow-y-scroll',
            currency ? 'mt-12' : 'mt-10',
          ]"
          class="z-20 absolute top-1 right-0 py-1 shadow-md bg-white shadow-2xl bg-white"
          @blur="isSelect = false"
        >
          <li
            v-show="optionData.length && !addBankData && !currency"
            v-for="(option, i) in optionData"
            :key="i"
            class="px-4 py-2 cursor-pointer hover:bg-newLightGreen text-sm hover:text-black flex justify-between items-center border-b border-dividerColor"
            :class="value == option ? ' bg-primary text-white' : ''"
            @click="update(option)"
          >
            <span> {{ option }}</span>
            <!-- <hr class="my-2 border w-full text-dividerColor" /> -->
          </li>

          <li
            v-show="addBankData && !optionData.length && !currency"
            @click="emitBool"
            class="px-4 py-2 cursor-pointer hover:bg-mint text-sm flex gap-x-3 items-center border-b border-dividerColor"
          >
            <span><i class="fas fa-plus text-primary"></i></span>
            <span class="text-dark-800 text-sm hover:text-primary"
              >Add Bank Account</span
            >
          </li>

          <li
            v-show="currency && !optionData.length && !addBankData"
            @click="
              () => {
                selectedCountry = countries['nigeria'];
                isSelect = !isSelect;
              }
            "
            class="px-4 py-2 cursor-pointer text-sm flex justify-between gap-x-3 items-center border-b border-dividerColor"
          >
            <div class="flex items-center gap-4">
              <img src="@/assets/icons/naira-icon.svg" alt="" />
              <span class="text-dark-800 text-sm">Nigeria Naira (NGN)</span>
            </div>
            <img src="@/assets/icons/nigeria-flag.svg" alt="" />
          </li>

          <li
            v-show="currency && !optionData.length && !addBankData"
            @click="
              () => {
                selectedCountry = countries['usa'];
                isSelect = !isSelect;
              }
            "
            class="px-4 py-2 cursor-pointer text-sm flex justify-between gap-x-3 items-center border-b border-dividerColor"
          >
            <div class="flex items-center gap-4">
              <img src="@/assets/icons/dollar-icon.svg" alt="" />
              <span class="text-dark-800 text-sm"
                >United States Dollar (USD)</span
              >
            </div>
            <img src="@/assets/icons/usa-flag.svg" alt="" />
          </li>

          <li
            v-show="currency && !optionData.length && !addBankData"
            @click="
              () => {
                selectedCountry = countries['britain'];
                isSelect = !isSelect;
              }
            "
            class="px-4 py-2 cursor-pointer text-sm flex justify-between gap-x-3 items-center border-b border-dividerColor"
          >
            <div class="flex items-center gap-4">
              <img src="@/assets/icons/pound-icon.svg" alt="" />
              <span class="text-dark-800 text-sm">United Kingdom (GDP)</span>
            </div>
            <img src="@/assets/icons/britain-flag.svg" alt="" />
          </li>
        </ul>
      </div>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-dark-500"
      >
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </div>
    </div>
    <div v-if="error" class="text-xs font-medium text-error mb-2">
      {{ error }}
    </div>
  </main>
  <div
    @click="isSelect = false"
    :class="!isSelect ? 'pointer-events-none' : ''"
    class="bg-transparent fixed inset-0"
  ></div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import naira from "@/assets/icons/naira-icon.svg";
import dollar from "@/assets/icons/dollar-icon.svg";
import pound from "@/assets/icons/pound-icon.svg";

import nigeria from "@/assets/icons/nigeria-flag.svg";
import usa from "@/assets/icons/usa-flag.svg";
import britain from "@/assets/icons/britain-flag.svg";

const props = defineProps({
  value: {
    default: "",
  },
  autoHeight: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: Boolean,
    default: false,
  },
  addBankData: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  options: {
    type: Array,
    default() {
      return [];
    },
  },
  placeholder: {
    type: String,
    default: "Select",
  },
  label: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update", "showModal"]);
const optionData = ref([]);
const propOptions = computed(() => props.options);
optionData.value = propOptions.value;

watch(
  propOptions,
  (val) => {
    if (val) {
      optionData.value = val;
    }
  },
  { deep: true }
);
const selectedCountry = ref({});

const countries = {
  nigeria: { flag: nigeria, currency: naira, text: "Nigeria Naira (NGN)" },
  usa: { flag: usa, currency: dollar, text: "United States Dollar (USD)" },
  britain: { flag: britain, currency: pound, text: "United Kingdom (GDP)" },
};

selectedCountry.value = countries.nigeria;
const searchQuery = ref("");

const isSelect = ref(false);
function emitBool() {
  emit("showModal", true);
}

function update(value) {
  isSelect.value = false;
  emit("update", value);
}

function searchFunction(e) {
  const name = e.target.value;
  let data;
  if (name.length) {
    if (e.key !== "Backspace") {
      data = props.options.filter((x) => {
        return String(x).toLowerCase().indexOf(name.toLowerCase()) > -1
          ? x
          : "";
      });
    } else {
      data = props.options.filter((x) => {
        return String(x)
          .toLowerCase()
          .indexOf(searchQuery.value.toLowerCase()) > -1
          ? x
          : "";
      });
    }

    if (data.length) {
      optionData.value = data;
      return data;
    }
  } else {
    optionData.value = props.options;
  }
}
</script>

<style>
#outline-style {
  border: 1px solid #11453b;
}
</style>
