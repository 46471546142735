<template>
  <main class="">
    <div class="flex flex-wrap my-4 md:my-10">
      <div class="text-left text-dark-800 px-4">
        <h2 class="text-base mb-2 font-bold md:text-2xl capitalize">
          Welcome {{ getAdminName }}
        </h2>

        <p class="text-sm">Pay and manage your employee in minutes</p>
      </div>
    </div>

    <div class="flex flex-wrap my-8 md:my-10">
      <div
        class="w-full lg:w-9/12 mb-12 lg:mb-0 px-2 md:px-4 flex flex-col gap-4"
      >
        <!-- //////// Wallet ///////// -->

        <div class="grid grid-cols-1 md:grid-cols-5 gap-4 items-center">
          <div
            style="border-radius: 24px"
            class="addWave md:col-span-3 bg-primary md:p-6 py-9 px-3 flex justify-between items-center"
          >
            <div class="text-left">
              <div class="flex gap-3 items-center">
                <span
                  style="font-weight: 500; font-size: 16px; line-height: 24px"
                  class="font-medium text-mint"
                  >Wallet Balance</span
                >
                <img
                  class="cursor-pointer"
                  @click="hideBalance = !hideBalance"
                  v-show="!hideBalance"
                  src="@/assets/icons/hide-icon.svg"
                  alt=""
                />
                <img
                  class="cursor-pointer"
                  @click="hideBalance = !hideBalance"
                  v-show="hideBalance"
                  src="@/assets/icons/eye-icon.svg"
                  alt=""
                />
              </div>
              <div class="flex items-center pt-2 gap-x-3">
                <span
                  v-show="hideBalance"
                  class="font-bold text-2xl sm:text-3xl text-white"
                  ><span
                    v-for="len in formatAmount(balance.balance).length"
                    :key="len"
                    >*</span
                  ></span
                >
                <span
                  v-show="!hideBalance"
                  class="font-bold text-2xl sm:text-3xl text-white"
                  >{{
                    balance.balance
                      ? formatAmount(balance.balance)
                      : formatAmount(0)
                  }}</span
                >
              </div>
            </div>
            <span
              :style="`${getTourLevel === 1 ? 'z-index:400' : ''}`"
              :class="getTourLevel === 1 ? 'pointer-events-none' : ''"
              class="relative"
            >
              <easiButton
                @click="$router.push('/admin/wallet/fund-wallet')"
                :color="'mint'"
                class="rounded-full"
              >
                <span class="text-sm text-primary sm:text-sm">Fund Wallet</span>
              </easiButton>
              <img
                v-if="getTourLevel === 1"
                class="w-10 transform rotate-45 absolute -top-16 -right-8"
                src="@/assets/img/pointer.png"
                alt=""
              />
            </span>
          </div>

          <div
            style="border-radius: 24px"
            class="addWave md:col-span-2 bg-paleShade md:p-6 py-9 px-3 flex justify-between items-center"
          >
            <div class="text-left w-full">
              <div class="flex justify-between w-full items-center">
                <span
                  style="font-weight: 500; font-size: 16px; line-height: 24px"
                  class="font-medium text-mint"
                  >Rewards Balance
                  <img
                    class="inline-block ml-2 cursor-pointer"
                    @click="hideRewardsBalance = !hideRewardsBalance"
                    v-show="!hideRewardsBalance"
                    src="@/assets/icons/hide-icon.svg"
                    alt="" />
                  <img
                    class="inline-block ml-2 cursor-pointer"
                    @click="hideRewardsBalance = !hideRewardsBalance"
                    v-show="hideRewardsBalance"
                    src="@/assets/icons/eye-icon.svg"
                    alt=""
                /></span>

                <button
                  @click="
                    () => {
                      showInfo = true;
                    }
                  "
                  class="justify-self-end flex items-center focus:outline-none"
                  style="
                    width: 20px;
                    height: 20px;
                    border-radius: 24px;
                    padding: 4px;
                    background-color: #29584f;
                    z-index: 5;
                  "
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 7.5L7.52766 7.48617C7.90974 7.29513 8.33994 7.64023 8.23634 8.05465L7.76366 9.94535C7.66006 10.3598 8.09026 10.7049 8.47234 10.5138L8.5 10.5M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8 5.5H8.005V5.505H8V5.5Z"
                      stroke="#E7E8E7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="flex items-center pt-2 gap-x-3">
                <span
                  v-show="hideRewardsBalance"
                  class="font-bold text-2xl sm:text-3xl text-white"
                  ><span
                    v-for="len in formatAmount(
                      balance.transactionFeeWalletBalance
                    ).length"
                    :key="len"
                    >*</span
                  ></span
                >
                <span
                  v-show="!hideRewardsBalance"
                  class="font-bold text-2xl sm:text-3xl text-white"
                  >{{
                    balance.transactionFeeWalletBalance
                      ? formatAmount(balance.transactionFeeWalletBalance)
                      : formatAmount(0)
                  }}</span
                >
              </div>
            </div>
            <span
              :style="`${getTourLevel === 1 ? 'z-index:400' : ''}`"
              :class="getTourLevel === 1 ? 'pointer-events-none' : ''"
              class="relative"
            >
              <img
                v-if="getTourLevel === 1"
                class="w-10 transform rotate-45 absolute -top-16 -right-8"
                src="@/assets/img/pointer.png"
                alt=""
              />
            </span>
          </div>
        </div>

        <!-- /////////  Services ////// -->
        <div
          :style="`${getTourLevel === 2 ? 'z-index:400' : ''}`"
          class="grid grid-cols-2 md:grid-cols-4 gap-4 xl:gap-6 mt-6 text-grey"
          :class="getTourLevel === 2 ? 'pointer-events-none' : ''"
        >
          <div
            v-for="(service, i) in services"
            :key="i"
            @click="
              $router.push(
                service.routeName
                  ? { name: service.routeName }
                  : { path: service.routePath }
              )
            "
            :class="`${service.color} hover:${service.color}-hover`"
            class="rounded-xl sm:rounded-2xl cursor-pointer w-full px-2 py-2 sm:px-4 sm:py-4 shadow-xl text-sm lg:px-4 md:mx-auto flex flex-col gap-2 md:gap-4 items-center lg:justify-center"
          >
            <div
              class="p-2 md:p-3 rounded-lg flex flex-col md:flex-row items-center justify-center"
            >
              <img
                class="w-9 md:w-auto"
                :src="service.icon"
                :alt="service.title"
              />
            </div>
            <span
              class="font-medium capitalize text-xs sm:text-sm text-left md:text-sm"
              >{{ service.title }}</span
            >
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/12 px-2 sm:px-4 text-dark-700">
        <div v-if="filterAutomations && filterAutomations.length">
          <div
            v-for="pay in filterAutomations"
            :key="pay._id"
            class="w-full flex flex-row lg:flex-col lg:w-10/12 mx-auto border-none shadow-lg rounded-2xl overflow-hidden bg-white"
          >
            <div
              class="hidden lg:block text-primary text-center p-3 bg-mint font-bold text-base shadow-md"
            >
              Next Payroll
            </div>

            <div
              class="w-full bg-white border-r lg:border-b lg:border-r-0 border-outlineGray flex flex-col gap-2 items-center justify-center px-4 sm:px-6 lg:px-4 py-6"
            >
              <h6 class="font-medium text-sm lg:hidden">Next Payroll</h6>
              <div class="flex items-center justify-center gap-2">
                <img src="@/assets/icons/calendar.svg" alt="" />
                <span class="text-sm">{{
                  getDateFromDay(pay.payrollDate).dateString
                }}</span>
              </div>
              <h4 class="text-primary font-bold text-sm md:text-lg">
                {{ getDateFromDay(pay.payrollDate).formattedDate }}
              </h4>
            </div>

            <div
              class="w-full bg-white flex flex-col gap-2 items-start lg:items-start justify-center px-4 sm:px-6 lg:px-4 py-4"
            >
              <h4>Total Employee</h4>

              <h3 class="text-dark-800 text-3xl text-left font-bold">
                {{ stats && stats.employees }}
              </h3>
              <div class="w-full flex flex-col">
                <div
                  :class="
                    stats && stats.employees ? '' : 'border border-dark-400 p-1'
                  "
                  class="w-full flex"
                >
                  <div
                    v-if="stats && stats.male"
                    :style="`width: ${stats && stats.malePercent}%`"
                    class="p-1 bg-primary"
                  ></div>
                  <div
                    v-if="stats && stats.female"
                    :style="`width: ${stats && stats.femalePercent}%`"
                    class="p-1 bg-secondary"
                  ></div>
                </div>
                <div
                  class="w-full flex justify-between text-left font-bold text-xs"
                >
                  <div class="flex flex-col">
                    <span>Male</span>
                    <span>{{ stats && stats.male }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span>Female</span>
                    <span>{{ stats && stats.female }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            class="w-full flex flex-row lg:flex-col lg:w-10/12 mx-auto border-none shadow-lg rounded-2xl overflow-hidden bg-white"
          >
            <div
              class="hidden lg:block text-primary text-center p-3 bg-mint font-bold text-base shadow-md"
            >
              Next Payroll
            </div>

            <div
              class="w-full bg-white border-r lg:border-b lg:border-r-0 border-outlineGray flex flex-col gap-2 items-center justify-center px-4 sm:px-6 lg:px-4 py-6"
            >
              <h6 class="font-medium lg:hidden">Next Payroll</h6>
              <div class="flex items-center justify-center gap-2">
                <img src="@/assets/icons/calendar.svg" alt="" />
                <span class="text-sm">{{
                  getLastDayOfMonth().dateString
                }}</span>
              </div>
              <h4 class="text-primary font-bold text-sm md:text-lg">
                {{ getLastDayOfMonth().formattedDate }}
              </h4>
            </div>

            <div
              class="w-full bg-white border-l lg:border-t lg:border-l-0 border-secondary flex flex-col gap-2 items-start lg:items-center justify-center px-4 sm:px-6 lg:px-4 py-6"
            >
              <h4>Total Employee</h4>

              <h3 class="text-dark-800 text-3xl text-left font-bold">
                {{ stats && stats.employees }}
              </h3>
              <div class="w-full flex flex-col">
                <div
                  :class="
                    stats && stats.employees ? '' : 'border border-dark-400 p-1'
                  "
                  class="w-full flex"
                >
                  <div
                    v-if="stats && stats.male"
                    :style="`width: ${stats && stats.malePercent}%`"
                    class="p-1 bg-primary"
                  ></div>
                  <div
                    v-if="stats && stats.female"
                    :style="`width: ${stats && stats.femalePercent}%`"
                    class="p-1 bg-secondary"
                  ></div>
                </div>
                <div
                  class="w-full flex justify-between text-left font-bold text-xs"
                >
                  <div class="flex flex-col">
                    <span>Male</span>
                    <span>{{ stats && stats.male }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span>Female</span>
                    <span>{{ stats && stats.female }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showKycBanner"
      class="flex flex-col gap-4 md:gap-2 md:flex-row justify-between bg-white items-center shadow-md mx-6 mb-10"
      style="border-radius: 24px; padding: 24px"
    >
      <div class="flex flex-col md:flex-row items-start md:items-center gap-3">
        <img src="../../assets/img/setup.svg" class="w-12 md:w-auto" alt="" />
        <div class="flex flex-col gap-2">
          <h4
            style="
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.2px;
              color: #191919;
            "
          >
            Complete your account setup today
          </h4>
          <p
            style="
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #474747;
            "
          >
            Enjoy the full benefit of the EaziLife. Complete your account set up
            now!
          </p>
        </div>
      </div>
      <div class="md:w-auto w-full mt-2 md:mt-0">
        <easiButton block
          ><span
            class="w-full md:w-32"
            @click="
              $router.push({ name: 'Register', query: { forSetup: true } })
            "
            >Finish setup</span
          ></easiButton
        >
      </div>
    </div>
    <PromotionalBanners />

    <div class="flex flex-wrap">
      <div
        :style="`${getTourLevel === 4 ? 'z-index:400' : ''}`"
        :class="getTourLevel === 4 ? 'pointer-events-none' : ''"
        class="w-full mb-12 xl:mb-0 px-2 md:px-4 relative"
      >
        <card-line-chart />
        <span :style="`${getTourLevel === 4 ? 'z-index:400' : ''}`" class="">
          <img
            v-if="getTourLevel === 4"
            class="w-10 transform rotate-45 absolute -top-20 right-20 md:right-40"
            src="@/assets/img/pointer.png"
            alt=""
          />
          <img
            v-if="getTourLevel === 4"
            class="w-10 transform rotate-45 absolute -top-20 right-6 md:right-56"
            src="@/assets/img/pointer.png"
            alt=""
          />
        </span>
      </div>
      <!-- <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div> -->
    </div>
    <!-- <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-page-visits />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-social-traffic />
      </div>
    </div> -->
    <div class="flex flex-wrap mt-6">
      <div class="w-full mb-12 xl:mb-0 px-2 md:px-4">
        <recent-activities />
      </div>
    </div>

    <Tour v-if="seeReward" />
    <easiRoundLoader v-if="loading" />

    <easiModal
      v-if="showInfo"
      @close="showInfo = false"
      :isBorder="false"
      size="sm"
    >
      <template v-slot:header>
        <h4
          style="
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #292a29;
          "
          class="-mt-6"
        >
          Reward Balance
        </h4>
      </template>

      <div class="grid grid-cols-1 gap-4" style="padding: 16px 24px">
        <span
          style="
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #515251;
          "
          >Your balance from referrals, rewards and giveaways are here.
        </span>

        <button
          style="border-radius: 24px; padding: 10px 28px 10px 28px; gap: 8px"
          @click="showInfo = false"
          block
          class="focus:outline-none bg-lighterGreen"
        >
          Dismiss
        </button>
      </div>
    </easiModal>

    <easiModal
      v-if="seeReward"
      :reward="true"
      @close="seeReward = false"
      :showCloseButton="false"
      :isBorder="false"
    >
      <template v-slot:header>
        <h3 class="reward-text mt-4 text-white">
          Claim your ₦5,000 wealth <br />
          summit bonus
        </h3></template
      >
      <div class="w-full h-full">
        <div class="absolute flex justify-center bottom-0 w-full">
          <button
            style="border-radius: 24px; padding: 10px 28px 10px 28px; gap: 8px"
            @click="
              () => {
                seeReward = false;
                showWallets = true;
                hideRewardsBalance = false;
              }
            "
            block
            size="small"
            class="bg-mint w-11/12 mb-3 focus:outline-none"
          >
            <span
              class="my-1"
              style="
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
              "
              >Claim now!</span
            >
          </button>
        </div>
      </div>
    </easiModal>

    <div
      :class="redirectToSetup === false ? 'pointer-events-none' : undefined"
      @click="
        () => {
          redirectToSetup === true
            ? $router.push({ name: 'BeforeDashboard' })
            : undefined;
        }
      "
      class="bg-transparent fixed inset-0"
      style="z-index: 999"
    ></div>
  </main>
</template>
<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { getToken, onMessage } from "firebase/messaging";

import CardLineChart from "@/components/Cards/CardLineChart.vue";
import CardBarChart from "@/components/Cards/CardBarChart.vue";
import CardPageVisits from "@/components/Cards/CardPageVisits.vue";
import CardSocialTraffic from "@/components/Cards/CardSocialTraffic.vue";
import Tour from "@/components/Dashboard/Tour.vue";
import RecentActivities from "@/components/Dashboard/RecentActivities.vue";
import PromotionalBanners from "@/components/Dashboard/PromotionalBanners.vue";

import employeeIcon from "@/assets/icons/d-employee.svg";
import complianceIcon from "@/assets/icons/d-compliance.svg";
import salaryIcon from "@/assets/icons/d-salary.svg";
import loanIcon from "@/assets/icons/d-loan.svg";

import taxImg from "@/assets/img/taxn.png";
import apiImg from "@/assets/img/api-n.png";
import tierZero from "@/assets/img/tier-zero.png";
import tierOne from "@/assets/img/tier-one.png";
import tierTwo from "@/assets/img/tier-two.png";
import tierThree from "@/assets/img/tier-three.png";
import tierBanner from "@/assets/img/tier-banner.png";
import tierBannerOne from "@/assets/img/tier-banner1.png";

import textsvg from "@/assets/icons/transfer-limit.svg";

export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    CardBarChart,
    CardPageVisits,
    CardSocialTraffic,
    RecentActivities,
    PromotionalBanners,
    Tour,
  },

  data: {
    loading: false,
    redirectToSetup: false,
  },

  setup() {
    const toast = useToast();
    const store = useDataStore();
    const { query, mutate } = store;
    const { getAllEmployees, getTourLevel, checkLoading } = storeToRefs(store);
    const { formatAmount, calculatePercentage, log } = helperFunctions;
    const automations = computed(() => store.getPayrollAutomation);
    const companyData = computed(() => store.getCompanyAdmin);
    const companyDetails = computed(() => store.getCompanyDetails);
    const kycInfo = computed(() =>
      store.getKyc && store.getKyc.data ? store.getKyc.data : {}
    );

    const earliestAutomation = ref([]);
    const showInfo = ref(false);
    const seeReward = ref(false);
    const stats = computed(() => {
      const employees =
        getAllEmployees.value &&
        getAllEmployees.value.data &&
        getAllEmployees.value.data.length;
      const male =
        getAllEmployees.value &&
        getAllEmployees.value.data &&
        getAllEmployees.value.data.filter((el) => el.profile.gender === "MALE");
      const female =
        getAllEmployees.value &&
        getAllEmployees.value.data &&
        getAllEmployees.value.data.filter(
          (el) => el.profile.gender === "FEMALE"
        );

      const obj = {
        employees,
        male: male && male.length,
        female: female && female.length,
        malePercent: (male && male.length * 100) / employees,
        femalePercent: (female && female.length * 100) / employees,
      };

      // log(obj);

      return obj;
    });

    const balance = computed(() => store.getWalletBalance);
    const hideBalance = ref(true);
    const hideRewardsBalance = ref(true);

    const filterAutomations = computed(() => {
      if (automations.value && automations.value.length) {
        const minDate = Math.min(
          ...automations.value.map(({ payrollDate }) => payrollDate)
        );

        let result = automations.value.filter(
          (aut) => aut.payrollDate === minDate && aut.paused !== true
        );
        return result;
      }
    });

    // }
    async function queryAutomations() {
      // loading.value = true;

      try {
        await query({
          endpoint: "ListPayrollAutomations",
          service: "PAYROLL",
          storeKey: "payrollAutomations",
        });
        // filterAutomations();
        //
      } catch (e) {
        console.log(e);
        //
      }
    }
    queryAutomations();

    const services = [
      {
        title: "Add Employee",
        icon: employeeIcon,
        routeName: "AddEmployee",
        color: "bg-white",
      },
      {
        title: "Pay Compliance",
        icon: complianceIcon,
        routeName: "RegisterCompliance",
        color: "bg-white",
      },
      {
        title: "Pay Salary",
        icon: salaryIcon,
        routeName: "PaySalary",
        color: "bg-white",
      },
      {
        title: "Quick Loans",
        icon: loanIcon,
        routeName: "LoanApplication",
        color: "bg-white",
      },
    ];

    function getLastDayOfMonth() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth();
      let d = new Date(Number(year), Number(month) + 1, 0);
      const day = d.toString().split(" ")[2];
      // console.log(getDateFromDay(day), "KI");

      return getDateFromDay(Number(day));
    }
    const accountType = window.localStorage.getItem("accountType");
    const currentTier = computed(() => {
      if (companyDetails.value && companyDetails.value.tier) {
        return Number(companyDetails.value.tier);
      }
      return 0;
    });
    const showKycBanner = computed(() => {
      // if (accountType && accountType !== "COMPANYADMIN") {
      //   return false;
      // }
      if (companyDetails.value && companyDetails.value.bvnVerified) {
        return false;
      }
      // if (route.path != "/admin/dashboard" && route.path != "/settings/profile") {
      //   return false;
      // }
      if (currentTier.value === 0) {
        // showThisBanner.value = true;
        return true;
      }

      if (kycInfo && kycInfo.value.personalInformation) {
        // showThisBanner.value = true;
        return false;
      }
      if (
        kycInfo &&
        kycInfo.value.personalInformation &&
        kycInfo.value.personalInformation.status
      ) {
        // showThisBanner.value = true;
        return false;
      }
      if (
        (kycInfo &&
          kycInfo.value.personalInformation &&
          kycInfo.value.personalInformation.status.toLowerCase() ==
            "verified") ||
        (kycInfo &&
          kycInfo.value.personalInformation &&
          kycInfo.value.personalInformation.status.toLowerCase() == "pending")
      ) {
        // showThisBanner.value = true;
        return false;
      }
      return true;
    });

    function getDateFromDay(d) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      let currentDay = new Date().getDate();

      let payrollMonth;
      if (Number(currentDay) > Number(d)) {
        payrollMonth = Number(currentMonth) + 1;
      } else {
        payrollMonth = Number(currentMonth);
      }

      let date = new Date(currentYear, payrollMonth, d);
      const dayInt = date.getDay();
      const dateString = days[dayInt];
      const fullDate = date.toLocaleDateString().split("/");

      const formattedDate = `${fullDate[1]}/${fullDate[0]}/${fullDate[2]}`;
      console.log(formattedDate, dateString);
      return {
        formattedDate,
        dateString,
      };
    }

    const lastDay = computed(() => {
      let month = new Date().getMonth();
      let year = new Date().getFullYear();

      let lastDay = new Date(year, month + 1, 0).getDate();
      return lastDay;
    });
    async function getFailedReason() {
      try {
        const res = await query({
          endpoint: "GetFailedKyc",
          service: "SETTINGS",
          storeKey: "reasonForFailure",
        });
        // console.log(res, "failure");
      } catch (e) {
        toast.error(e.message);
        console.log(e);
      }
    }
    onMounted(async () => {
      await getFailedReason();
    });
    return {
      query,
      mutate,
      toast,
      store,
      hideBalance,
      formatAmount,
      balance,
      automations,
      // getDateFromDay,
      getLastDayOfMonth,
      filterAutomations,
      stats,
      toast,
      services,
      log,
      getTourLevel,
      automations,
      lastDay,
      getDateFromDay,
      hideRewardsBalance,
      showInfo,
      seeReward,
      showKycBanner,
    };
  },
  mounted() {
    const seen = window.localStorage.getItem("hasSeenReward");
    const setupComp = window.localStorage.getItem("hasSetup");
    this.redirectToSetup = false;
    if (!setupComp && this.$route.query.from === "signup") {
      window.localStorage.setItem("hasSetup", true);
      this.redirectToSetup = true;
      // setTimeout(() => {
      //   this.$router.push({ name: "BeforeDashboard" });
      // }, 3000);
    }
    if (setupComp && this.$route.query.from === "signup" && !seen) {
      window.localStorage.setItem("hasSeenReward", true);
      this.seeReward = true;
    }
    onMessage(this.$messaging, (payload) => {
      const { title, body } = payload.notification;

      if (Notification.permission === "granted") {
        this.showNotification(title, body);
      } else {
        console.log("Push notification received, but permission not granted.");
      }
    });
  },

  computed: {
    ...mapWritableState(useDataStore, ["getCompanyDetails", "getCompanyAdmin"]),
    companyData: (store) => store.getCompanyDetails,
    // companyData: (store) => store.getCompanyAdmin,
    getAdminName() {
      let name = "";
      // console.log("Company Admin Details...");
      // this.log(this.companyData);

      name =
        this.companyData &&
        this.companyData.company &&
        this.companyData.company.companyName;

      // console.log("Name", name);

      return name;
    },
  },
  methods: {
    requestPermission() {
      console.log("Requesting permission...");
      window.Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          this.getFirebaseToken();
        } else {
          console.log("Notification permission not granted.");
        }
      });
    },

    async getFirebaseToken() {
      const isNotification = window.localStorage.getItem("isNotification");
      if (!isNotification) {
        const vapidKey = `BFf-Zh5n9Ti6f8ZVQZSXPBKFavz7urJfqMS9wsYjin6UaWMgYu02b_MIXEcXpLR2U6NjMeypq2msQJdX3I4Wj1s`;

        getToken(this.$messaging, { vapidKey })
          .then((currentToken) => {
            if (currentToken) {
              this.enableNotification(currentToken);
              window.localStorage.setItem("isNotification", "enabled");
            } else {
              this.requestPermission();
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      } else {
        console.log("Notification already enabled");
      }
    },

    async enableNotification(token) {
      try {
        let res = await this.mutate({
          endpoint: "EnableNotifications",
          data: { token },
          service: "AUTH",
        });
        if (res.success) {
          this.toast.success(res.message);
        } else {
          this.toast.error(res.message);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async showNotification(title, body) {
      if ("Notification" in window) {
        const options = {
          body,
          icon: "/path/to/notification-icon.png", // Replace with the path to your notification icon
        };

        // Display the notification
        new Notification(title, options);
      }
    },

    async queryCompany() {
      // this.loading = true;
      try {
        let res = await this.query({
          endpoint: "FetchCompaniesByAdminId",
          service: "SETTINGS",
          storeKey: "companyDetails",
        });
        this.loading = false;
        console.log(res, "Company Details");
      } catch (err) {
        this.loading = false;
      }
      // this.log(this.companyDetails);
    },
    async queryCompanyAdmin() {
      try {
        // this.loading = true;
        await this.query({
          endpoint: "FetchCompanyAdmin",
          service: "SETTINGS",
          storeKey: "companyAdmin",
        });
        this.loading = false;
        console.log("Company Details");
        // this.log(this.companyDetails);
      } catch (err) {
        this.loading = false;
      }
    },

    async queryWalletBalance() {
      try {
        const id = "";
        // this.loading = true;
        await this.query({
          endpoint: "ViewWalletBalance",
          payload: {
            input: {
              companyId: id,
            },
          },
          service: "PAYROLL",
          storeKey: "walletBalance",
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    async queryAllowance() {
      await this.query({
        endpoint: "ListAllowance",
        payload: {
          paging: {
            limit: 1000,
            skip: 0,
            search: null,
          },
        },
        service: "PAYROLL",
        storeKey: "allowance",
      });
    },

    async queryEmployees() {
      await this.query({
        endpoint: "ListEmployees",
        payload: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
            sort: { firstName: "ASC" },
          },
          filter: [],
        },
        service: "EMP",
        storeKey: "listEmployees",
      });
    },

    async queryArchived() {
      await this.query({
        endpoint: "ListEmployees",
        payload: {
          archived: true,
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
            sort: { firstName: "ASC" },
          },
          filter: [],
        },
        service: "EMP",
        storeKey: "listArchivedEmployees",
      });
    },

    async queryBonusOrDed() {
      try {
        await this.query({
          endpoint: "ListBonusAndDeduction",
          payload: {
            input: {
              paging: {
                limit: 1000,
                skip: 0,
                search: "",
              },
              type: "Bonus",
            },
          },
          service: "PAYROLL",
          storeKey: "listBonusAndDeduction",
        });
      } catch (err) {
        console.log(err);
      }
    },

    async queryBonus() {
      try {
        await this.query({
          endpoint: "ListBonusAndDeduction",
          payload: {
            input: {
              paging: {
                limit: 1000,
                skip: 0,
                search: "",
              },
              type: "Bonus",
            },
          },
          service: "PAYROLL",
          storeKey: "bonuses",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async queryDeduction() {
      try {
        await this.query({
          endpoint: "ListBonusAndDeduction",
          payload: {
            input: {
              paging: {
                limit: 1000,
                skip: 0,
                search: "",
              },
              type: "Deduction",
            },
          },
          service: "PAYROLL",
          storeKey: "deductions",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async queryAutomation() {
      try {
        await this.query({
          endpoint: "ListPayrollAutomations",
          service: "PAYROLL",
          storeKey: "payrollAutomations",
        });
      } catch (err) {
        console.log(err);
      }
    },

    async queryLoan() {
      try {
        await this.query({
          endpoint: "ListLoans",
          payload: {
            paging: {
              limit: 1000,
              skip: 0,
              search: "",
            },
            filter: {
              type: null,
              status: null,
            },
            sort: {
              createdAt: -1,
            },
          },
          service: "LOAN",
          storeKey: "loans",
        });
        console.log(allLoans.value);
      } catch (err) {
        console.log(err);
      }
    },
  },
  async created() {
    await this.queryCompany();
    await this.queryWalletBalance();
    await this.queryEmployees();
    await this.queryArchived();

    await this.queryCompanyAdmin();

    this.queryAllowance();
    this.queryBonusOrDed();
    this.queryBonus();
    this.queryDeduction();
    this.queryAutomation();
    this.getFirebaseToken();

    // this.queryLoan();
  },
};
</script>

<style scoped>
.addWave {
  background-image: url("../../assets/img/wav.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.reward-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: center;
}
</style>
